import React, { useEffect, useState } from 'react';
import { Table, Button, message, Space, Modal, Carousel, Form, Input } from 'antd';
import axios from 'axios';

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [statusFilter, setStatusFilter] = useState('pending');
  const [searchTerm, setSearchTerm] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [formData, setFormData] = useState({});
  const hosturl ="https://api.savefarmer.in"
  const [userMapping, setUserMapping] = useState({});

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${hosturl}/api/posts`);
        setPosts(response.data);
        filterPosts(response.data, statusFilter, searchTerm);
      } catch (error) {
        message.error('Failed to fetch posts');
      }
    };

    fetchPosts();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${hosturl}/api/app-users`);
        const users = response.data.reduce((acc, user) => {
          acc[user._id] = { username: user.username, mobileNumber: user.phoneNumber };
          return acc;
        }, {});
        setUserMapping(users);
      } catch (error) {
        message.error('Failed to fetch users');
      }
    };

    fetchUsers();
  }, []);

  const filterPosts = (posts, status, search) => {
    const filtered = posts.filter(post => {
      const matchesStatus = post.status === status;
      const normalizedSearch = search.toLowerCase();
      const user = userMapping[post.userId] || {};
      
      const matchesSearch =
        (user.username && user.username.toLowerCase().includes(normalizedSearch)) ||
        (user.mobileNumber && user.mobileNumber.includes(normalizedSearch)) ||
        (post.title && post.title.toLowerCase().includes(normalizedSearch)) || 
        (post.description && post.description.toLowerCase().includes(normalizedSearch)) ||
        (post.userId && post.userId.toLowerCase().includes(normalizedSearch)); // Add this line
  
      return matchesStatus && (search ? matchesSearch : true);
    });
  
    setFilteredPosts(filtered);
  };
  

  const handleStatusChange = (newStatus) => {
    setStatusFilter(newStatus);
    filterPosts(posts, newStatus, searchTerm);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    filterPosts(posts, statusFilter, value);
  };

  const updatePostStatus = async (id, newStatus) => {
    try {
      await axios.patch(`${hosturl}/api/posts/${id}/status`, { status: newStatus });
      message.success('Post status updated');
      const updatedPosts = posts.map(post =>
        post._id === id ? { ...post, status: newStatus } : post
      );
      setPosts(updatedPosts);
      filterPosts(updatedPosts, statusFilter, searchTerm);
    } catch (error) {
      message.error('Failed to update post status');
    }
  };

  const deletePost = async (id) => {
    Modal.confirm({
      title: 'Confirm Delete',
      content: 'Are you sure you want to delete this post?',
      onOk: async () => {
        try {
          await axios.delete(`${hosturl}/api/posts/${id}`);
          message.success('Post deleted successfully');
          const updatedPosts = posts.filter(post => post._id !== id);
          setPosts(updatedPosts);
          filterPosts(updatedPosts, statusFilter, searchTerm);
        } catch (error) {
          message.error('Failed to delete post');
        }
      },
    });
  };

  const fetchUserDetails = async (userId, post) => {
    try {
      const response = await axios.get(`${hosturl}/api/app-users/${userId}`);
      setUserDetails(response.data);
      setSelectedPost(post);
      setIsModalVisible(true);
    } catch (error) {
      message.error('Failed to fetch user details');
    }
  };

  const handleEditPost = (post) => {
    setFormData(post);
    setIsEditModalVisible(true);
  };

  const handleEditSubmit = async (values) => {
    try {
      await axios.patch(`${hosturl}/api/posts/${formData._id}`, values);
      message.success('Post updated successfully');
      const updatedPosts = posts.map(post =>
        post._id === formData._id ? { ...post, ...values } : post
      );
      setPosts(updatedPosts);
      filterPosts(updatedPosts, statusFilter, searchTerm);
      setIsEditModalVisible(false);
    } catch (error) {
      message.error('Failed to update post');
    }
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setUserDetails(null);
    setSelectedPost(null);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Image',
      dataIndex: 'images',
      key: 'images',
      render: (images) => (
        images && images.length > 0 ? (
          <img src={`${hosturl}${images[0]}`} alt="Post" style={{ width: '100px', height: 'auto' }} />
        ) : (
          <span>No Image</span>
        )
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Expected Price',
      dataIndex: 'expectedPrice',
      key: 'expectedPrice',
    },
    {
      title: 'Available From',
      dataIndex: 'availableFrom',
      key: 'availableFrom',
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space>
          <Button onClick={() => fetchUserDetails(record.userId, record)} style={buttonStyle}>View User</Button>
          <Button onClick={() => handleEditPost(record)} style={buttonStyle}>Edit</Button>
          {record.status === 'pending' && (
            <>
              <Button onClick={() => updatePostStatus(record._id, 'approved')} style={buttonStyle}>Approve</Button>
              <Button onClick={() => updatePostStatus(record._id, 'rejected')} style={buttonStyle}>Reject</Button>
            </>
          )}
          <Button onClick={() => deletePost(record._id)} type="danger" style={buttonStyle}>Delete</Button>
        </Space>
      ),
    },
  ];

  const buttonStyle = {
    borderRadius: '8px',
    backgroundColor: '#1890ff',
    color: '#fff',
    border: 'none',
    transition: 'background-color 0.3s',
  };

  return (
    <div style={{ padding: '20px', borderRadius: '10px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', overflowY: 'auto', maxHeight: '100%' }}>
      <h4 style={{ color: '#333', fontWeight: 'bold' }}>Posts</h4>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
        <Space>
          <Button onClick={() => handleStatusChange('pending')} style={buttonStyle}>Pending</Button>
          <Button onClick={() => handleStatusChange('approved')} style={buttonStyle}>Approved</Button>
          <Button onClick={() => handleStatusChange('rejected')} style={buttonStyle}>Rejected</Button>
        </Space>
        <Input
          placeholder="Search by Username, ID, or Phone Number"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ width: '300px', borderRadius: '8px', boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)' }}
        />
      </div>
      {filteredPosts.length === 0 ? (
        <div style={{ textAlign: 'center', margin: '20px 0', color: 'red', fontWeight: 'bold' }}>
          No posts found matching the current filters.
        </div>
      ) : (
        <Table 
          dataSource={filteredPosts} 
          columns={columns} 
          rowKey="_id" 
          pagination={{ pageSize: 5 }} 
          style={{ borderRadius: '10px', overflow: 'hidden' }} 
          bordered
        />
      )}

      <Modal
        title="User & Post Details"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width={800}
      >
        {userDetails && selectedPost && (
          <div>
            <h4 style={{ color: '#333' }}>User Details</h4>
            <p><strong>User ID:</strong> {userDetails._id}</p>
            <p><strong>Username:</strong> {userDetails.username}</p>
            <p><strong>Phone Number:</strong> {userDetails.phoneNumber}</p>

            <h4 style={{ color: '#333' }}>Post Details</h4>
            <p><strong>Title:</strong> {selectedPost.title}</p>
            <p><strong>Category:</strong> {selectedPost.category}</p>
            <p><strong>Quantity:</strong> {selectedPost.quantity}</p>
            <p><strong>Expected Price:</strong> {selectedPost.expectedPrice}</p>
            <p><strong>Available From:</strong> {new Date(selectedPost.availableFrom).toLocaleDateString()}</p>
            <p><strong>Phone Number:</strong> {selectedPost.mobileNumber}</p>
            <p><strong>Address:</strong> {selectedPost.address}</p>
            <p><strong>State:</strong> {selectedPost.state}</p>
            <p><strong>District:</strong> {selectedPost.district}</p>
            <p><strong>Status:</strong> {selectedPost.status}</p>
            <p><strong>Description:</strong> {selectedPost.description}</p>

            {selectedPost.images && selectedPost.images.length > 0 && (
              <Carousel style={{ textAlign: 'center' }}>
                {selectedPost.images.map((image, index) => (
                  <div key={index}>
                    <img 
                      src={`${hosturl}${image}`} 
                      alt={`Post Image ${index + 1}`} 
                      style={{ width: '100%', height: 'auto', maxHeight: '400px', objectFit: 'contain' }} 
                    />
                  </div>
                ))}
              </Carousel>
            )}
          </div>
        )}
      </Modal>

      <Modal
        title="Edit Post"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        <Form
          layout="vertical"
          initialValues={formData}
          onFinish={handleEditSubmit}
        >
          <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Title is required' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="category" label="Category" rules={[{ required: true, message: 'Category is required' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true, message: 'Description is required' }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item 
            name="quantity" 
            label="Quantity" 
            rules={[{ required: true, message: 'Quantity is required' }]}
          >
            <Input placeholder="Enter quantity (e.g., 1500 kg)" />
          </Form.Item>

          <Form.Item 
            name="expectedPrice" 
            label="Expected Price" 
            rules={[{ required: true, message: 'Expected Price is required' }]}
          >
            <Input placeholder="Enter expected price (e.g., 150 /kg)" />
          </Form.Item>

          <Form.Item 
            name="availableFrom" 
            label="Available From" 
            rules={[{ required: true, message: 'Available date is required' }]}
          >
            <Input type="date" placeholder="Select available date" />
          </Form.Item>

          <Form.Item name="mobileNumber" label="Mobile Number" rules={[{ required: true, message: 'Mobile Number is required' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="address" label="Address" rules={[{ required: true, message: 'Address is required' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="state" label="State" rules={[{ required: true, message: 'State is required' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="district" label="District" rules={[{ required: true, message: 'District is required' }]}>
            <Input />
          </Form.Item>
          <Form.Item name="village" label="Village">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Update Post</Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Posts;

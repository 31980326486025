import React, { useEffect, useState } from 'react';
import { List, Input, Button } from 'antd';
import axios from 'axios';

const API_URL = 'https://api.savefarmer.in/days';

const App = () => {
  const [days, setDays] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    const fetchDays = async () => {
      const response = await axios.get(API_URL);
      setDays(response.data);
    };
    fetchDays();
  }, []);

  const handleAddOrUpdateDays = async () => {
    const numberOfDays = parseInt(inputValue, 10);
    
    if (isNaN(numberOfDays) || numberOfDays < 0) return;

    if (editingId) {
      await axios.put(`${API_URL}/${editingId}`, { daysCount: numberOfDays });
      setDays(days.map((day) => (day._id === editingId ? { ...day, daysCount: numberOfDays } : day)));
      setEditingId(null);
    } else {
      const response = await axios.post(API_URL, { daysCount: numberOfDays });
      setDays([...days, response.data]);
    }

    setInputValue('');
  };

  const handleEditDays = (id) => {
    const dayToEdit = days.find(day => day._id === id);
    setInputValue(dayToEdit.daysCount);
    setEditingId(id);
  };

  const handleDeleteDays = async (id) => {
    await axios.delete(`${API_URL}/${id}`);
    setDays(days.filter(day => day._id !== id));
    if (editingId === id) {
      setInputValue('');
      setEditingId(null);
    }
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      background: '#f0f2f5', // Light background
    },
    card: {
      maxWidth: '400px',
      width: '100%',
      padding: '20px',
      background: '#fff',
      borderRadius: '8px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    },
    title: {
      textAlign: 'center',
      marginBottom: '20px',
      fontSize: '24px',
      color: '#333',
    },
    inputField: {
      marginBottom: '10px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      padding: '10px',
    },
    submitButton: {
      width: '100%',
      background: '#4CAF50',
      color: '#fff',
      border: 'none',
      borderRadius: '4px',
      padding: '10px',
      cursor: 'pointer',
    },
    listItem: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px',
      borderBottom: '1px solid #eee',
    },
    editButton: {
      color: '#1890ff',
      marginRight: '10px',
      cursor: 'pointer',
    },
    deleteButton: {
      color: 'red',
      cursor: 'pointer',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h1 style={styles.title}>Days Tracker</h1>
        <Input
          type="number"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          placeholder="Enter number of days"
          style={styles.inputField}
        />
        <Button style={styles.submitButton} onClick={handleAddOrUpdateDays}>
          {editingId ? 'Update Days' : 'Add Days'}
        </Button>

        <List
          bordered
          dataSource={days}
          renderItem={(day) => (
            <List.Item style={styles.listItem}>
              <span>{day.daysCount} days</span>
              <div>
                <span
                  style={styles.editButton}
                  onClick={() => handleEditDays(day._id)}
                >
                  Edit
                </span>
                <span
                  style={styles.deleteButton}
                  onClick={() => handleDeleteDays(day._id)}
                >
                  Delete
                </span>
              </div>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
};

export default App;

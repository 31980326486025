import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { 
  Input, 
  Button, 
  List, 
  Typography, 
  message, 
  Form 
} from 'antd';

const { Title } = Typography;

const QnAComponent = () => {
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const hosturl ="https://api.savefarmer.in"

  const fetchData = async () => {
    const response = await axios.get(`${hosturl}/api/qas`);
    setData(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    const { question, answer } = values;
    try {
      if (values.editId) {
        // Update existing Q&A
        await axios.put(`${hosturl}/api/qas/${values.editId}`, { question, answer });
        message.success('Q&A updated successfully!');
      } else {
        // Create new Q&A
        await axios.post(`${hosturl}/api/qas`, { question, answer });
        message.success('Q&A added successfully!');
      }
      form.resetFields();
    } catch (error) {
      message.error('Error saving Q&A. Please try again.');
    } finally {
      fetchData();
    }
  };

  const handleEdit = (item) => {
    form.setFieldsValue({ question: item.question, answer: item.answer, editId: item._id });
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${hosturl}/api/qas/${id}`);
      message.success('Q&A deleted successfully!');
    } catch (error) {
      message.error('Error deleting Q&A. Please try again.');
    } finally {
      fetchData();
    }
  };

  return (
    <div style={styles.container}>
      <Title level={2} style={styles.title}>Q&A List</Title>
      <Form form={form} onFinish={handleSubmit} layout="vertical" style={styles.form}>
        <Form.Item name="question" rules={[{ required: true, message: 'Please enter a question' }]}>
          <Input placeholder="Enter question" style={styles.input} />
        </Form.Item>
        <Form.Item name="answer" rules={[{ required: true, message: 'Please enter an answer' }]}>
          <Input placeholder="Enter answer" style={styles.input} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={styles.button}>
            {form.getFieldValue('editId') ? 'Update' : 'Add'}
          </Button>
        </Form.Item>
      </Form>
      <List
        itemLayout="horizontal"
        dataSource={data}
        renderItem={item => (
          <List.Item style={styles.item}>
            <List.Item.Meta
              title={<strong>Q:</strong> + item.question}
              description={<strong>A:</strong> + item.answer}
            />
            <div style={styles.buttonGroup}>
              <Button onClick={() => handleEdit(item)} style={styles.editButton}>Edit</Button>
              <Button onClick={() => handleDelete(item._id)} style={styles.deleteButton}>Delete</Button>
            </div>
          </List.Item>
        )}
        style={styles.itemList}
      />
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    backgroundColor: '#f0f4f8',
    height: '100vh',
    overflowY: 'auto',
  },
  title: {
    textAlign: 'center',
    color: '#343a40',
  },
  form: {
    marginBottom: '20px',
  },
  input: {
    marginBottom: '10px',
  },
  button: {
    width: '100%',
  },
  itemList: {
    marginBottom: '40px',
  },
  item: {
    padding: '10px',
    border: '1px solid #e9ecef',
    borderRadius: '4px',
    marginBottom: '10px',
  },
  buttonGroup: {
    display: 'flex',
  },
  editButton: {
    marginLeft: '5px',
  },
  deleteButton: {
    marginLeft: '5px',
  },
};

export default QnAComponent;

import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Statistic, Typography } from 'antd';
import { PieChartOutlined, UserOutlined, FileTextOutlined, CommentOutlined, FlagOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

const Dashboard = () => {
  const [counts, setCounts] = useState({
    posts: 0,
    approved: 0,
    pending: 0,
    rejected: 0,
    states: 0,
    categories: 0,
    districts: 0,
    feedbacks: 0,
    users: 0,
  });
  
  const hosturl ="https://api.savefarmer.in"
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const [posts, states, categories, feedbacks, users, postCounts, districts] = await Promise.all([
          axios.get(`${hosturl}/api/posts`),
          axios.get(`${hosturl}/api/states`),
          axios.get(`${hosturl}/api/categories`),
          axios.get(`${hosturl}/feedback`),
          axios.get(`${hosturl}/api/app-users`),
          axios.get(`${hosturl}/api/posts/counts`),
          axios.get(`${hosturl}/api/districts`) // Fetch all districts
        ]);

        console.log("Posts Data:", posts.data);
        console.log("States Data:", states.data);
        console.log("Categories Data:", categories.data);
        console.log("Feedbacks Data:", feedbacks.data);
        console.log("Users Data:", users.data);
        console.log("Post Counts Data:", postCounts.data);
        console.log("Districts Data:", districts.data); // Log districts data

        setCounts({
          posts: posts.data.length,
          approved: postCounts.data.approved,
          pending: postCounts.data.pending,
          rejected: postCounts.data.rejected,
          states: states.data.length,
          categories: categories.data.length,
          feedbacks: feedbacks.data.length,
          users: users.data.length,
          districts: districts.data.length, // Get total count of districts
        });
      } catch (error) {
        console.error('Error fetching counts:', error);
      }
    };

    fetchCounts();
  }, []);

  return (
    <div style={styles.container}>
      <style>
        {`
          .dashboard-card {
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            transition: transform 0.3s, box-shadow 0.3s;
            text-align: center;
            margin-bottom: 16px;
            cursor: pointer; /* Add cursor pointer to indicate clickable */
          }

          .dashboard-card:hover {
            transform: scale(1.05);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
          }

          .header {
            text-align: center;
            margin-bottom: 40px;
          }
        `}
      </style>

      <div className="header">
        <Title level={3} style={{ color: '#1890ff' }}>Dashboard Overview</Title>
      </div>
      
      <Row gutter={16}>
        <Col span={8} onClick={() => navigate("/home/Posts")}>
          <Card className="dashboard-card" hoverable>
            <Statistic title="Total Posts" value={counts.posts} prefix={<FileTextOutlined />} />
          </Card>
        </Col>
        <Col span={8} onClick={() => navigate("/home/Posts")}>
          <Card className="dashboard-card" hoverable>
            <Statistic title="Approved Posts" value={counts.approved} />
          </Card>
        </Col>
        <Col span={8} onClick={() => navigate("/home/Posts")}>
          <Card className="dashboard-card" hoverable>
            <Statistic title="Pending Posts" value={counts.pending} />
          </Card>
        </Col>
        <Col span={8} onClick={() => navigate("/home/Posts")}>
          <Card className="dashboard-card" hoverable>
            <Statistic title="Rejected Posts" value={counts.rejected} />
          </Card>
        </Col>
      </Row>

      <div className="header" style={{ marginTop: '40px' }}>
        <Title level={3} style={{ color: '#40a9ff' }}>Additional Statistics</Title>
      </div>

      <Row gutter={16}>
        <Col span={8} onClick={() => navigate("/home/UserDetails")}>
          <Card className="dashboard-card" hoverable>
            <Statistic title="Total Users" value={counts.users} prefix={<UserOutlined />} />
          </Card>
        </Col>
        <Col span={8} onClick={() => navigate("/home/Category")}>
          <Card className="dashboard-card" hoverable>
            <Statistic title="Total Categories" value={counts.categories} prefix={<PieChartOutlined />} />
          </Card>
        </Col>
        <Col span={8} onClick={() => navigate("/home/Category")}>
          <Card className="dashboard-card" hoverable>
            <Statistic title="Total States" value={counts.states} prefix={<FlagOutlined />} />
          </Card>
        </Col>
        <Col span={8} onClick={() => navigate("/home/Category")}>
          <Card className="dashboard-card" hoverable>
            <Statistic title="Total Districts" value={counts.districts} />
          </Card>
        </Col>
        <Col span={8} onClick={() => navigate("/home/feedback")}>
          <Card className="dashboard-card" hoverable>
            <Statistic title="Total Feedbacks" value={counts.feedbacks} prefix={<CommentOutlined />} />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#f0f2f5',
    padding: '20px',
    maxHeight: '100%',
    overflowY: 'scroll',
    transition: 'background-color 0.3s',
  },
};

export default Dashboard;

import React, { useState, useEffect } from 'react';
import { Form, Input, Button, List, Typography, message } from 'antd';

const { Title, Paragraph } = Typography;

const FeedbackForm = () => {
    const [name, setName] = useState('');
    const [review, setReview] = useState('');
    const [feedbacks, setFeedbacks] = useState([]);
    const [editingId, setEditingId] = useState(null);
    const hosturl ="https://api.savefarmer.in"

    useEffect(() => {
        fetchFeedbacks();
    }, []);

    const fetchFeedbacks = async () => {
        try {
            const response = await fetch(`${hosturl}/feedback`);
            const data = await response.json();
            setFeedbacks(data);
        } catch (error) {
            message.error('Error fetching feedbacks');
        }
    };

    const handleSubmit = async (values) => {
        const feedback = { name: values.name, review: values.review };
        try {
            if (editingId) {
                await fetch(`${hosturl}/feedback/${editingId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(feedback),
                });
                message.success('Feedback updated successfully');
            } else {
                await fetch(`${hosturl}/feedback`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(feedback),
                });
                message.success('Feedback submitted successfully');
            }
            resetForm();
            fetchFeedbacks();
        } catch (error) {
            message.error('Error submitting feedback');
        }
    };

    const handleEdit = (feedback) => {
        setName(feedback.name);
        setReview(feedback.review);
        setEditingId(feedback._id);
    };

    const handleDelete = async (id) => {
        try {
            await fetch(`${hosturl}/feedback/${id}`, {
                method: 'DELETE',
            });
            message.success('Feedback deleted successfully');
            fetchFeedbacks();
        } catch (error) {
            message.error('Error deleting feedback');
        }
    };

    const resetForm = () => {
        setName('');
        setReview('');
        setEditingId(null);
    };

    return (
        <div style={styles.container}>
            <Title level={3} style={styles.title}>Feedback Form</Title>
            <Form
                layout="vertical"
                onFinish={handleSubmit}
                style={styles.form}
            >
                <Form.Item
                    label="Name"
                    required
                    name="name"
                >
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter your name"
                    />
                </Form.Item>
                <Form.Item
                    label="Review"
                    required
                    name="review"
                >
                    <Input.TextArea
                        value={review}
                        onChange={(e) => setReview(e.target.value)}
                        placeholder="Enter your review"
                        rows={4}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" style={styles.button}>
                        {editingId ? 'Update' : 'Submit'}
                    </Button>
                </Form.Item>
            </Form>
            <Title level={3} style={styles.subtitle}>Feedback List</Title>
            <div style={styles.scrollableList}>
                <List
                    itemLayout="horizontal"
                    dataSource={feedbacks}
                    renderItem={feedback => (
                        <List.Item
                            actions={[
                                <Button type="link" onClick={() => handleEdit(feedback)}>Edit</Button>,
                                <Button type="link" danger onClick={() => handleDelete(feedback._id)}>Delete</Button>
                            ]}
                        >
                            <List.Item.Meta
                                title={<strong>{feedback.name}</strong>}
                                description={<Paragraph>{feedback.review}</Paragraph>}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
};

const styles = {
    container: {
        maxWidth: '90%',
        margin: '0 auto',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
        overflowY: 'auto',
        maxHeight:'100vh',
    },
    subtitle: {
        textAlign: 'center',
        marginTop: '20px',
    },
    button: {
        width: '100%',
    },
    scrollableList: {
        maxHeight: '300px', // Set the max height for the scrollable area
        overflowY: 'auto', // Enable vertical scrolling
        marginTop: '20px',
        border: '1px solid #f0f0f0', // Optional: Add a border for clarity
        borderRadius: '5px',
        padding: '10px', // Optional: Add some padding
        marginBottom:'50px'

    },
};

export default FeedbackForm;

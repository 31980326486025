import React, { useEffect, useState } from 'react';
import { Table, Spin, message, Input, Button, Modal, Card, Typography } from 'antd';
import axios from 'axios';

const { Search } = Input;
const { Title, Text } = Typography;

const UserTable = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [deletingUserId, setDeletingUserId] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const hosturl ="https://api.savefarmer.in"

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${hosturl}/api/app-users`);
        const sortedUsers = response.data.sort((a, b) => new Date(b.registrationDate) - new Date(a.registrationDate));
        setUsers(sortedUsers);
      } catch (error) {
        message.error('Failed to fetch users');
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [hosturl]);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  const handleDeleteConfirmation = (userId) => {
    setDeletingUserId(userId);
    Modal.confirm({
      title: 'Are you sure you want to delete this user?',
      content: 'This action cannot be undone.',
      okText: 'Delete',
      okType: 'danger',
      onOk: () => handleDelete(userId),
      onCancel: () => setDeletingUserId(null),
    });
  };

  const handleDelete = async (userId) => {
    try {
      await axios.delete(`${hosturl}/api/app-users/${userId}`);
      message.success('User deleted successfully');
      setUsers(users.filter(user => user._id !== userId));
    } catch (error) {
      message.error('Failed to delete user');
      console.error("Error deleting user:", error);
    } finally {
      setDeletingUserId(null);
    }
  };

  const handleViewUserDetails = (user) => {
    setSelectedUser(user);
  };

  const handleCloseDetailsModal = () => {
    setSelectedUser(null);
  };

  const filteredUsers = users.filter(user =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.phoneNumber.includes(searchTerm) ||
    user._id.includes(searchTerm)
  );

  const columns = [
    {
      title: 'S.No',
      key: 'sno',
      render: (_, __, index) => index + 1,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Profile Image',
      dataIndex: 'profileImage',
      key: 'profileImage',
      render: (text) => (
        <img
          src={`${hosturl}/uploads/profileImages/${text}`}
          alt="Profile"
          style={styles.profileImage}
        />
      ),
    },
    {
      title: 'Registration Date',
      dataIndex: 'registrationDate',
      key: 'registrationDate',
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <>
          <Button
            type="primary"
            onClick={() => handleViewUserDetails(record)}
            style={styles.actionButton}
          >
            View Details
          </Button>
          <Button
            type="danger"
            onClick={() => handleDeleteConfirmation(record._id)}
            style={styles.actionButton}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <Card bordered={false} style={styles.card}>
      <Title level={4}>User List</Title>
      <Search
        placeholder="Search by username, phone number, or user ID"
        onSearch={handleSearch}
        style={styles.search}
        allowClear
        enterButton
      />
      {loading ? (
        <Spin size="large" />
      ) : (
        <Table
          dataSource={filteredUsers}
          columns={columns}
          rowKey="_id"
          scroll={{ y: 400 }}
          pagination={{ pageSize: 10 }}
          style={styles.table}
        />
      )}

      {/* User Details Modal */}
      <Modal
        title="User Details"
        visible={!!selectedUser}
        onCancel={handleCloseDetailsModal}
        footer={null}
        width={600}
      >
        {selectedUser && (
          <div>
            <Text strong>ID:</Text> {selectedUser._id}<br />
            <Text strong>Username:</Text> {selectedUser.username}<br />
            <Text strong>Phone Number:</Text> {selectedUser.phoneNumber}<br />
            <Text strong>Profile Image:</Text><br />
            <img
              src={`${hosturl}/uploads/profileImages/${selectedUser.profileImage}`}
              alt="Profile"
              style={styles.modalProfileImage}
            /><br />
            <Text strong>Registration Date:</Text> {new Date(selectedUser.registrationDate).toLocaleString()}<br />
            <Text strong>Address:</Text> {JSON.stringify(selectedUser.address)}<br />
            <Text strong>Bank Account:</Text> {JSON.stringify(selectedUser.bankaccount)}<br />
          </div>
        )}
      </Modal>
    </Card>
  );
};

// Inline styles
const styles = {
  card: {
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#f9f9f9',
    height:'100%',
    overflowY : 'auto',
  },
  profileImage: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  modalProfileImage: {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
  actionButton: {
    marginRight: '8px',
    transition: 'background-color 0.3s',
    marginBottom:'10px'
  },
  search: {
    marginBottom: '16px',
    width:'50%'
  },
  table: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  },
};

export default UserTable;

import React, { useState, useEffect } from 'react';
import { Upload, Button, Row, Col, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';

const ImageUploader = () => {
    const [imageList, setImageList] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([]);
    const hosturl ="https://api.savefarmer.in"

    useEffect(() => {
        fetchImages();
    }, []);

    const fetchImages = async () => {
        try {
            const imageResponse = await fetch(`${hosturl}/api/images`);
            const imageData = await imageResponse.json();
            const fullImageUrls = imageData.map(url => `${hosturl}${url}`); // Construct full URLs
            setUploadedImages(fullImageUrls); // Set the state with full URLs
        } catch (error) {
            message.error('Failed to fetch images.');
        }
    };

    const handleChange = (info) => {
        if (info.fileList.length > 6) {
            message.error("You can only upload a maximum of 6 images.");
            return;
        }
        setImageList(info.fileList.map(file => ({
            ...file,
            url: URL.createObjectURL(file.originFileObj),
        })));
    };

    const handleRemove = (file) => {
        setImageList(prevList => prevList.filter(item => item.uid !== file.uid));
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        imageList.forEach(file => {
            formData.append('images', file.originFileObj);
        });

        try {
            await axios.post(`${hosturl}/api/images/upload`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            message.success('Images uploaded successfully!');
            setImageList([]); // Clear the uploaded image list
            fetchImages(); // Refresh the image list
        } catch (error) {
            message.error('Failed to upload images.');
        }
    };

    const handleDelete = async (filename) => {
        try {
            await axios.delete(`${hosturl}/api/images/${filename}`);
            message.success('Image deleted successfully!');
            fetchImages(); // Refresh the image list
        } catch (error) {
            message.error('Failed to delete image.');
        }
    };

    return (
        <div style={{ padding: '20px', backgroundColor: '#f0f2f5', borderRadius: '8px', overflowY:'auto', maxHeight:'100vh' }}>
            <h5>Hero images</h5>
            <Upload
                multiple
                listType="picture-card"
                fileList={imageList}
                onChange={handleChange}
                onRemove={handleRemove}
                beforeUpload={() => false}
            >
                <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            <Button 
                type="primary" 
                onClick={handleSubmit} 
                style={{ marginTop: '16px' }}
                disabled={imageList.length === 0}
            >
                Submit
            </Button>
            <Row gutter={16} style={{ marginTop: '16px' }}>
                {uploadedImages.map((url, index) => {
                    const filename = url.split('/').pop(); // Extract filename for deletion
                    return (
                        <Col span={6} key={index}>
                            <div style={{ position: 'relative' }}>
                                <img
                                    src={url}
                                    alt={`Uploaded ${index}`}
                                    style={{ width: '100%', borderRadius: '4px', objectFit: 'cover' }}
                                />
                                <Button
                                    onClick={() => handleDelete(filename)}
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '5px',
                                        background: 'red',
                                        color: 'white',
                                        border: 'none',
                                    }}
                                >
                                    x
                                </Button>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};

export default ImageUploader;

import React, { useState, useEffect } from 'react';
import { 
  FaUserCog, 
  FaClipboardList, 
  FaChartPie, 
  FaHistory, 
  FaWallet, 
  FaSignOutAlt, 
  FaBars, 
  FaTimes, 
  FaPowerOff 
} from 'react-icons/fa';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import Logo from './Circle_logo.png';
import './HomePageComponent.css';

const HomePageComponent = ({onLogout}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState('/home/dashboard'); // Default active menu item
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/home/dashboard');
    }
  }, [location.pathname, navigate]);

  const toggleSidebar = () => setIsSidebarOpen(prev => !prev);
  
  const handleMenuClick = (route) => {
    setActiveMenu(route); // Update active menu state
    navigate(route);
  };

  return (
    <div className="page-container">
      <div className={`sidebar-container ${isSidebarOpen ? 'open' : 'closed'}`}>
        <div className="sidebar-logo-container">
          <img className="sidebar-logo-icon" src={Logo} alt="AWG Circle" />
          {isSidebarOpen && (
            <div className="sidebar-company-info">
              <h4 className="sidebar-company-name">Save Farmer</h4>
            </div>
          )}
        </div>

        <div className="sidebar-menu">
          <div 
            className={`menu-item ${activeMenu === '/home/Dashboard' ? 'active' : ''}`} 
            onClick={() => handleMenuClick('/home/Dashboard')}
          >
            {isSidebarOpen && <span>Dashboard</span>}
            <FaUserCog className="sidebar-icon" />
          </div>
          <div 
            className={`menu-item ${activeMenu === '/home/UserDetails' ? 'active' : ''}`} 
            onClick={() => handleMenuClick('/home/UserDetails')}
          >
            {isSidebarOpen && <span>Users</span>}
            <FaClipboardList className="sidebar-icon" />
          </div>
          <div 
            className={`menu-item ${activeMenu === '/home/Posts' ? 'active' : ''}`} 
            onClick={() => handleMenuClick('/home/Posts')}
          >
            {isSidebarOpen && <span>Posts</span>}
            <FaChartPie className="sidebar-icon" />
          </div>
          <div 
            className={`menu-item ${activeMenu === '/home/Mainpage' ? 'active' : ''}`} 
            onClick={() => handleMenuClick('/home/Mainpage')}
          >
            {isSidebarOpen && <span>Mainpage</span>}
            <FaHistory className="sidebar-icon" />
          </div>
          <div 
            className={`menu-item ${activeMenu === '/home/Category' ? 'active' : ''}`} 
            onClick={() => handleMenuClick('/home/Category')}
          >
            {isSidebarOpen && <span>Category</span>}
            <FaWallet className="sidebar-icon" />
          </div>
          <div 
            className={`menu-item ${activeMenu === '/home/Termsandconditions' ? 'active' : ''}`} 
            onClick={() => handleMenuClick('/home/poststimeline')}
          >
            {isSidebarOpen && <span>Post Timeline</span>}
            <FaClipboardList className="sidebar-icon" />
          </div>
          <div 
            className={`menu-item ${activeMenu === '/home/Termsandconditions' ? 'active' : ''}`} 
            onClick={() => handleMenuClick('/home/Termsandconditions')}
          >
            {isSidebarOpen && <span>Terms and Conditions</span>}
            <FaClipboardList className="sidebar-icon" />
          </div>
          <div 
            className={`menu-item ${activeMenu === '/home/Privacypolicy' ? 'active' : ''}`} 
            onClick={() => handleMenuClick('/home/Privacypolicy')}
          >
            {isSidebarOpen && <span>Privacy Policy</span>}
            <FaClipboardList className="sidebar-icon" />
          </div>
          <div 
            className={`menu-item ${activeMenu === '/home/howtouse' ? 'active' : ''}`} 
            onClick={() => handleMenuClick('/home/howtouse')}
          >
            {isSidebarOpen && <span>How to use</span>}
            <FaClipboardList className="sidebar-icon" />
          </div>
          <div 
            className={`menu-item ${activeMenu === '/home/feedback' ? 'active' : ''}`} 
            onClick={() => handleMenuClick('/home/feedback')}
          >
            {isSidebarOpen && <span>Feedback</span>}
            <FaClipboardList className="sidebar-icon" />
          </div>
          <div 
            className={`menu-item ${activeMenu === '/home/qna' ? 'active' : ''}`} 
            onClick={() => handleMenuClick('/home/qna')}
          >
            {isSidebarOpen && <span>Faq's</span>}
            <FaClipboardList className="sidebar-icon" />
          </div>
          <div 
            className={`menu-item ${activeMenu === '/home/Contactdetails' ? 'active' : ''}`} 
            onClick={() => handleMenuClick('/home/Contactdetails')}
          >
            {isSidebarOpen && <span>Contact Details</span>}
            <FaUserCog className="sidebar-icon" />
          </div>
          <div 
            className={`menu-item ${activeMenu === '/home/Aboutus' ? 'active' : ''}`} 
            onClick={() => handleMenuClick('/home/Aboutus')}
          >
            {isSidebarOpen && <span>About Us</span>}
            <FaClipboardList className="sidebar-icon" />
          </div>
          <div 
        className={`menu-item ${activeMenu === '/' ? 'active' : ''}`} 
        onClick={() => {
          onLogout(); // Call the logout function
          handleMenuClick('/'); // Navigate to the login page
        }}
      >
        {isSidebarOpen && <span>Logout</span>}
        <FaSignOutAlt className="sidebar-icon" />
      </div>
        </div>
      </div>

      <div className={`header-container ${isSidebarOpen ? 'header-expanded' : 'header-collapsed'}`}>
        <div className="header-content">
          {isSidebarOpen ? (
            <FaTimes className="menu-icon" color='#ccc' size={25} onClick={toggleSidebar} />
          ) : (
            <FaBars className="menu-icon" color='#ccc' size={25} onClick={toggleSidebar} />
          )}
          <div className="actions-container" onClick={() => handleMenuClick('/')}>
            <FaPowerOff className="power-icon" color='white' />
          </div>
        </div>
      </div>

      <div className={`main-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        <Outlet />
      </div>
    </div>
  );
};

export default HomePageComponent;

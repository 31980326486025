import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Layout, Button, List, Input, Select, message } from 'antd';

const { Option } = Select;
const { Content } = Layout;

const App = () => {
    const hosturl = 'https://api.savefarmer.in';
    const [categories, setCategories] = useState([]);
    const [states, setStates] = useState([]);
    const [districts, setDistricts] = useState({});
    const [newCategory, setNewCategory] = useState('');
    const [editingCategory, setEditingCategory] = useState(null);
    const [newState, setNewState] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [newDistrict, setNewDistrict] = useState('');
    const [editingDistrict, setEditingDistrict] = useState(null);
    const [categoryImage, setCategoryImage] = useState(null);
    const [categoryOrder, setCategoryOrder] = useState([]);

    useEffect(() => {
        fetchCategories();
        fetchStates();
    }, []);

    const fetchCategories = async () => {
        const response = await axios.get(`${hosturl}/api/categories`);
        const sortedCategories = response.data.sort((a, b) => a.position - b.position);
        setCategories(sortedCategories);
        setCategoryOrder(sortedCategories.map(cat => cat._id));
    };

    const fetchStates = async () => {
        const response = await axios.get(`${hosturl}/api/states`);
        setStates(response.data);
    };

    const fetchDistricts = async (state) => {
        const response = await axios.get(`${hosturl}/api/districts/${state}`);
        setDistricts((prev) => ({ ...prev, [state]: response.data }));
    };

    const addCategory = async () => {
        if (newCategory && categoryImage) {
            const formData = new FormData();
            formData.append('category', newCategory);
            formData.append('image', categoryImage);
            await axios.post(`${hosturl}/api/categories`, formData);
            message.success('Category added!');
            resetCategoryForm();
            fetchCategories();
        } else {
            message.error('Please enter a category name and upload an image.');
        }
    };

    const editCategory = async () => {
        if (editingCategory) {
            const formData = new FormData();
            formData.append('category', newCategory);
            if (categoryImage) {
                formData.append('image', categoryImage);
            }
            await axios.put(`${hosturl}/api/categories/${editingCategory._id}`, formData);
            message.success('Category updated!');
            resetCategoryForm();
            fetchCategories();
        }
    };

    const deleteCategory = async (category) => {
        await axios.delete(`${hosturl}/api/categories/${category._id}`);
        message.success('Category deleted!');
        fetchCategories();
    };

    const resetCategoryForm = () => {
        setNewCategory('');
        setEditingCategory(null);
        setCategoryImage(null);
    };

    const handlePositionChange = (id, value) => {
        const currentPosition = categoryOrder.indexOf(id) + 1; // Get current position
        if (value < 1 || value > categories.length) {
            message.error('Position must be between 1 and ' + categories.length);
            return;
        }
        if (value === currentPosition) {
            message.error('This is the current position!');
            return;
        }
        const newOrder = [...categoryOrder];
        newOrder.splice(newOrder.indexOf(id), 1); // Remove the category from its current position
        newOrder.splice(value - 1, 0, id); // Insert it at the new position
        setCategoryOrder(newOrder);
    };

    const handlePositionUpdate = async (id) => {
        try {
            await axios.put(`${hosturl}/api/categories/positionorder`, { order: categoryOrder });
            message.success('Category order updated!');
            fetchCategories();
        } catch (error) {
            console.error("Failed to update category order:", error);
            message.error('Failed to update category order. Check console for details.');
        }
    };

    const addState = async () => {
        if (newState) {
            await axios.post(`${hosturl}/api/states`, { state: newState });
            message.success('State added!');
            setNewState('');
            fetchStates();
        } else {
            message.error('Please enter a state name.');
        }
    };

    const deleteState = async (state) => {
        await axios.delete(`${hosturl}/api/states/${state._id}`);
        message.success('State deleted!');
        fetchStates();
    };

    const addDistrict = async () => {
        if (selectedState && newDistrict) {
            await axios.post(`${hosturl}/api/districts`, { state: selectedState, district: newDistrict });
            message.success('District added!');
            setNewDistrict('');
            fetchDistricts(selectedState);
        } else {
            message.error('Please select a state and enter a district name.');
        }
    };

    const deleteDistrict = async (district) => {
        await axios.delete(`${hosturl}/api/districts/${district._id}`);
        message.success('District deleted!');
        fetchDistricts(selectedState);
    };

    const handleEditDistrict = (district) => {
        setNewDistrict(district.name);
        setEditingDistrict(district);
    };

    return (
        <Layout>
            <Content style={{ padding: '50px', overflowY: 'auto', maxHeight: '100vh' }}>
                <div>
                    <h2>Categories</h2>
                    <Input
                        value={newCategory}
                        onChange={(e) => setNewCategory(e.target.value)}
                        placeholder="Add new category"
                        style={{ marginBottom: '10px' }}
                    />
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => setCategoryImage(e.target.files[0])}
                        style={{ marginBottom: '10px' }}
                    />
                    <Button onClick={editingCategory ? editCategory : addCategory} type="primary" style={{ marginLeft: '10px' }}>
                        {editingCategory ? 'Update Category' : 'Add Category'}
                    </Button>
                    <List
                        bordered
                        dataSource={categories}
                        renderItem={item => {
                            const currentPosition = categoryOrder.indexOf(item._id) + 1;
                            return (
                                <List.Item
                                actions={[
                                    <Button onClick={() => { setEditingCategory(item); setNewCategory(item.name); }} type="link">Edit</Button>,
                                    <Button onClick={() => deleteCategory(item)} type="link">Delete</Button>
                                ]}
                            >
                                <img src={`${hosturl}${item.image}`} alt={item.name} style={{ width: '50px', marginRight: '10px' }} />
                                {item.name}
                                <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                                    {/* <span>{categoryOrder.indexOf(item._id) + 1}</span> */}
                                </div>
                                <Input
                                    type="number"
                                    placeholder="Position"
                                    min={1}
                                    max={categories.length}
                                    onChange={(e) => handlePositionChange(item._id, parseInt(e.target.value))}
                                    style={{ marginLeft: '10px', width: '100px' }}
                                    value={categoryOrder.indexOf(item._id) + 1} // Display the current position
                                />
                                <Button onClick={() => handlePositionUpdate(item._id)} type="link" style={{ marginLeft: '10px' }}>OK</Button>
                            </List.Item>
                            
                            );
                        }}
                        style={{ marginTop: '20px' }}
                    />
                </div>

                <div style={{ marginTop: '20px' }}>
                    <h2>States</h2>
                    <Input
                        value={newState}
                        onChange={(e) => setNewState(e.target.value)}
                        placeholder="Add new state"
                        style={{ marginRight: '10px' }}
                    />
                    <Button onClick={addState} type="primary">Add State</Button>
                    <List
                        bordered
                        dataSource={states}
                        renderItem={item => (
                            <List.Item
                                actions={[
                                    <Button onClick={() => deleteState(item)} type="link">Delete</Button>
                                ]}
                            >
                                {item.name}
                            </List.Item>
                        )}
                        style={{ marginTop: '20px' }}
                    />
                </div>

                <div style={{ marginTop: '20px' }}>
                    <h2>Districts</h2>
                    <Select
                        style={{ width: 200, marginRight: '10px' }}
                        onChange={(value) => {
                            setSelectedState(value);
                            fetchDistricts(value);
                        }}
                    >
                        {states.map((state) => (
                            <Option key={state._id} value={state._id}>{state.name}</Option>
                        ))}
                    </Select>
                    <Input
                        value={newDistrict}
                        onChange={(e) => setNewDistrict(e.target.value)}
                        placeholder="Add new district"
                        style={{ marginRight: '10px' }}
                    />
                    <Button onClick={addDistrict} type="primary">Add District</Button>
                    <List
                        bordered
                        dataSource={districts[selectedState] || []}
                        renderItem={item => (
                            <List.Item
                                actions={[
                                    <Button onClick={() => handleEditDistrict(item)} type="link">Edit</Button>,
                                    <Button onClick={() => deleteDistrict(item)} type="link">Delete</Button>
                                ]}
                            >
                                {item.name}
                            </List.Item>
                        )}
                        style={{ marginBottom: '40px' }}
                    />
                </div>
            </Content>
        </Layout>
    );
};

export default App;
